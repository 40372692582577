// Large devices (desktops, less than 1200px)
@media (max-width: 1200.98px) {
	nav.section-header {
		.header-flexbox {
			.flexbox-nav-wrapper {
				.flexbox-nav-inner {
					.flexbox-nav {
						li {
							padding: 0 10px;
						}
					}
				}
			}
		}
	}

	.section-heading {
		.heading-wrapper {
			.heading-left {
				&.md-small {
					h1 {
						font-size: 38px;
					}
				}
			}

			.heading-right {
				transform: translatex(80px);
			}
		}
	}

	section.section-welcome {
		.welcome-background {
			.background-image {
				.image-inner {
					.inner-box {
						width: 85%;
					}
				}
			}
		}
	}

	section.static-page-section-content {
		.wave-3 {
			right: -3%;
		}
	}
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
	.section-heading {
		.heading-wrapper {
			.heading-left {
				.bg-color {
					right: -20%;
				}
			}

			.heading-right {
				transform: translatex(60px);
			}
		}
	}

	nav.section-header {
		.header-flexbox {
			.flexbox-logo {
				img {
					height: 45px;
				}
			}

			.flexbox-nav-wrapper {
				.flexbox-nav-inner {
					.flexbox-nav {
						li {
							a {
								font-size: 12px;
							}
						}
					}
				}
			}

			.flexbox-contact {
				a {
					font-weight: 600;
					font-size: 12px;
					padding: 0 5px;

					span {
						display: none;
					}
				}
			}
		}
	}

	section.section-welcome {
		.welcome-background {
			.background-image {
				.image-inner {
					bottom: -50px;
					left: -50px;

					.inner-box {
						width: 100%;

						.btn-blue {
							display: none;
						}

						ul {
							li {
								.link {
									font-size: 15px;
								}
							}
						}

						.box-image {
							svg {
								transform: translateX(50%);
								width: 150px;
								height: 150px;
							}
						}
					}
				}
			}

			.background-content {
				.content-color {
					width: 100%;
				}
			}
		}

		.welcome-inner {
			width: 57%;
		}
	}

	section.section-faq {
		.owl-carousel-wrapper {
			padding-right: 0;
		}
	}

	section.section-rodo {
		.rodo-flexbox {
			.flexbox-description {
				width: 60%;
			}

			.flexbox-image {
				width: 40%;
			}
		}
	}

	section.section-iod {
		.iod-flexbox {
			.flexbox-description {
				width: 56%;
			}

			.flexbox-image {
				width: 40%;
			}


			.flexbox-dot {
				transform: translate(20%, -50%);
			}
		}
	}

	section.static-page-section-content {
		.content-cta {
			.contact-cta {
				.cta-bg {
					left: 5%;
				}

				a:first-of-type {
					transform: translatex(-60px);
				}

				.wave-4 {
					right: -25%;
				}
			}
		}
	}

	section.section-footer {

		.footer-slogan {
			p {
				font-size: 13px;
			}
		}

		.alert {
			margin-top: 30px;

			p {
				text-align: center;
				font-size: 12px;
			}

			.alert-links {
				display: flex;
				justify-content: center;
			}
		}

		.footer-flexbox {
			flex-direction: column;
			align-items: center;

			.flexbox-service {
				text-align: center;
				margin-top: 30px;

				.list-social {
					justify-content: center;
				}
			}
		}

		.footer-credits-wrapper {
			.footer-credits {
				flex-direction: column;
				text-align: center;

				p {
					font-size: 10px;
				}

				.footer-payment {
					margin-top: 10px;

					p {
						font-size: 10px;
					}
				}
			}
		}
	}
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {

	.nav-button {
		display: flex;
		margin-right: -10px;

		&.hidden {
			display: flex;
		}

		&.nav-button-welcome {
			display: none;
		}

		.button-bar {
			&.button-bar-color {
				background-color: #dbdbdd;
			}
		}
	}

	nav.section-header {
		width: 100%;

		&.hidden {
			display: block;
			width: 100%;
			height: 100%;
			visibility: visible;
			opacity: 1;
		}

		.header-flexbox {
			.flexbox-logo {
				&.hidden {
					display: block;
				}
			}

			.flexbox-nav-wrapper {
				position: fixed;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				overflow-y: scroll;
				background: #fff;
				z-index: 555;
				opacity: 0;
				visibility: hidden;
				transition: all 0.3s;

				.nav-button-close {
					display: block;
				}

				.flexbox-nav-inner {
					display: flex;
					flex-direction: column;
					justify-content: center;
					min-height: 100%;
					padding: 50px;
					margin-right: 0;

					.flexbox-nav {
						display: flex;
						flex-direction: column;
						align-items: center;

						li {
							padding: 10px 0;

							a {
								font-size: 16px;
							}
						}
					}
				}

				&.active {
					opacity: 1;
					visibility: visible;
				}
			}

			.flexbox-contact {
				&.hidden {
					display: flex;
				}

				a {
					font-weight: 600;
					font-size: 12px;
					padding: 0 10px;

					span {

						display: block;
					}

					img {
						display: none;
					}
				}
			}
		}
	}

	.section-heading {
		.heading-wrapper {
			.heading-left {
				.bg-color {
					.wave-1 {
						right: 4%;
					}
				}

				&.md-small {
					h1 {
						font-size: 32px;
						line-height: 40px;
					}
				}
			}

			.heading-right {
				&.md-small {
					h2 {
						font-size: 26px;
					}
				}
			}
		}
	}

	section.section-welcome {
		.welcome-background {
			justify-content: space-between;

			.background-image {
				width: 25%;

				.image-inner {
					display: none;
				}
			}

			.background-content {
				width: 85%;

				.content-color {
					width: 100%;
				}
			}
		}

		.welcome-inner {
			width: 100%;

			.inner-flexbox {
				.inner-logo {
					display: none;
				}
			}

			.inner-social-media {
				margin-bottom: 70px;
			}
		}
	}

	section.section-iod,
	section.section-rodo {

		.iod-flexbox,
		.rodo-flexbox {
			flex-direction: column;

			.wave-2 {
				left: 20%;
			}

			.flexbox-description {
				width: 100%;
				padding: 25px;

				.btn {
					margin-top: 20px;
				}
			}

			.flexbox-image {
				width: 100%;
				min-height: 100px;
				padding: 15px;

				.wave-1 {
					display: none;
				}

				.wave-2 {
					top: 85%;
				}
			}

			.flexbox-dot {
				display: none;
			}
		}
	}

	section.section-tagline {
		.owl-carousel {
			.item {
				.item-service {
					width: 100%;
				}
			}
		}
	}

	section.static-page-section-content {
		.content-cta {
			.contact-cta {
				width: 47%;

				.cta-bg {
					left: 25%;
				}

				a:first-of-type {
					transform: translatex(-22px);
				}
			}
		}
	}

	section.section-kontakt {
		.section-wrapper {
			flex-direction: column;

			align-items: center;

			.box-contact {
				width: 100%;
				align-items: center;
				text-align: center;
				padding-bottom: 20px;
			}

			.map-wrapper {
				width: 100%;
			}
		}
	}
}

// Extra small devices (portrait phones, less than 600px)
@media (max-width: 600px) {
	body {
		.btn {
			&.btn-small {
				padding: 8px 15px;
			}
		}
	}

	.section-heading {
		.heading-wrapper {
			.heading-left {
				width: 45%;
				padding-top: 15px;

				.bg-color {
					height: 160px;
					right: -15%;

					.wave-1 {
						height: 12px;
						right: 2%;
					}
				}

				h1 {
					font-size: 40px;
				}

				&.md-small {
					h1 {
						font-size: 26px;
						line-height: 32px;
					}
				}
			}

			.heading-right {
				width: 45%;
				padding-top: 20px;
				transform: translatex(0);

				h2 {
					font-size: 20px;
				}

				&.md-small {
					h2 {
						font-size: 20px;
					}
				}
			}
		}
	}

	nav.section-header {
		.header-flexbox {
			.flexbox-logo {
				img {
					height: 45px;
				}
			}

			.flexbox-contact {
				transform: translatex(-10px);

				a {
					padding: 0 5px;
					font-size: 10.5px;
				}
			}
		}
	}

	section.section-welcome {
		min-height: auto;

		.welcome-background {
			.background-image {
				display: none;
			}

			.background-content {
				width: 100%;
			}
		}

		.welcome-inner {
			padding: 0;

			.inner-description {
				transform: translateY(-30px);
				text-align: center;

				h1 {
					line-height: 50px;
					font-size: 40px;
				}

				ul {
					padding-left: 0;

					a {
						font-size: 14px;
						text-align: left;
					}
				}
			}

			.inner-social-media {
				display: flex;
				justify-content: center;

				p {
					font-size: 15px;
				}
			}
		}
	}

	section.section-iod,
	section.section-rodo {
		margin-top: 30px;

		.iod-flexbox,
		.rodo-flexbox {

			.flexbox-description {
				text-align: center;

				p {
					width: 100%;
				}
			}
		}
	}

	section.section-faq {
		padding: 50px 0 40px 0;
	}

	section.section-tagline {
		display: none;
	}

	section.static-page-section-content {
		.wave-3 {
			display: none;
		}

		.page-inner {
			flex-direction: column;
			align-items: center;

			.box-hidden {
				&.box-hidden-content {
					width: 100%;
					padding-right: 0;
					padding-top: 20px;
					flex-direction: row;
					align-items: center;

					h4 {
						&:not(:last-of-type) {
							margin-right: 5px;
						}
					}
				}
			}

			.page-content {
				width: 100%;
				padding-top: 20px;

				.content-description {
					h3 {
						font-size: 24px;
						line-height: 32px;
					}
				}

				.faq-item:not(:first-of-type) {
					padding-top: 20px;
				}
			}
		}
	}
}

// Extra small devices (portrait phones, less than 400px)
@media (max-width: 400px) {
	.section-heading {
		height: 150px;

		.heading-wrapper {
			.heading-left {
				padding-top: 10px;

				.bg-color {
					height: 120px;
				}

				h1 {
					font-size: 30px;
				}

				&.md-small {
					h1 {
						font-size: 24px;
					}
				}
			}

			.heading-right {
				padding-top: 22px;

				h2 {
					font-size: 20px;
				}

				&.md-small {
					h2 {
						font-size: 18px;
					}
				}
			}
		}
	}

	section.static-page-section-content {
		padding: 20px 0 30px 0;

		.page-inner {
			transform: translatey(0);

			.box-hidden {
				&.box-hidden-content {
					h4 {
						font-size: 18px;
					}
				}
			}

			.page-content {
				.content-description {
					p {
						text-align: center;
					}

					ul {
						text-align: left;
					}
				}
			}
		}

		.content-cta {
			flex-direction: column;
			padding: 40px 0;

			.heading-cta {
				width: 100%;
				text-align: center;
				margin-bottom: 60px;
			}

			.contact-cta {
				width: 100%;

				.wave-4 {
					right: 0;
					bottom: -38%;
					height: 12px;
				}

				.cta-bg {
					left: 28%;
					height: 150px;
				}

				a {
					&:first-of-type {
						transform: translatex(18px);
					}

					&:nth-of-type(2) {
						transform: translatex(150px);
					}
				}
			}
		}
	}

	section.section-kontakt {
		padding: 20px 0 50px 0;

		.section-wrapper {
			.map-wrapper {
				height: 300px;

				iframe {
					left: 2.5%;
					width: 300px;
				}

				.wave-2 {
					left: 15%;
					top: 90%;
					transform: translateX(-60%) rotate(-25deg);
				}
			}
		}
	}
}