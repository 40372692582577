@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Montserrat Light'), local('Montserrat-Light'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_cJD3gfD-w.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459Wdhzg.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Montserrat Medium'), local('Montserrat-Medium'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_ZpC3gfD-w.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_bZF3gfD-w.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Montserrat Bold'), local('Montserrat-Bold'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_dJE3gfD-w.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_c5H3gfD-w.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local('Montserrat Black'), local('Montserrat-Black'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_epG3gfD-w.ttf) format('truetype');
}
body {
  min-height: 100vh;
  height: 100%;
  font-family: 'Montserrat', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: hidden;
}
body.no-scroll {
  overflow: hidden;
  max-height: 100vh;
  padding-right: 17px;
}
body .btn {
  padding: 15px 35px;
  border-radius: 0;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 1px;
  transition: all 0.3s;
  white-space: normal;
}
body .btn img {
  margin-left: 15px;
  height: 10px;
}
body .btn.btn-default {
  background: none;
  color: #222;
  border: 1px solid #222;
}
body .btn.btn-default:hover {
  background: #222;
  border-color: #222;
  color: #fff;
}
body .btn.btn-blue {
  background: #0090ff;
  color: #fff;
  border: 1px solid #0090ff;
}
body .btn.btn-blue:hover {
  background: #0082e6;
  border-color: #0082e6;
}
body .btn.btn-small {
  padding: 5px 15px;
  font-size: 10px;
}
body ul,
body ol,
body p,
body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  margin: 0;
  padding: 0;
}
body a {
  text-decoration: none !important;
}
.alert-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.2);
  z-index: 555;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s;
}
.alert-wrapper .alert-service {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  transform: translateX(8px);
  padding: 50px 0;
}
.alert-wrapper .alert-service .service-inner {
  padding: 50px;
  background: #fff;
  width: 100%;
  box-shadow: 0px 0px 32px 1px rgba(0, 0, 0, 0.15);
  text-align: center;
  border-top: 5px solid #fff;
  position: relative;
  transform: scale(0.8);
  transition: all 0.5s;
}
.alert-wrapper .alert-service .service-inner .inner-dismiss {
  top: 10px;
  right: 10px;
  position: absolute;
  border: 0;
  background: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  padding: 5px;
  outline: none;
}
.alert-wrapper .alert-service .service-inner .inner-dismiss img {
  height: 15px;
}
.alert-wrapper .alert-service .service-inner.success {
  border-top: 5px solid #78b042;
}
.alert-wrapper .alert-service .service-inner.warning {
  border-top: 5px solid #d51a27;
}
.alert-wrapper .alert-service .service-inner.info {
  border-top: 5px solid #1a88d5;
}
.alert-wrapper.active {
  opacity: 1;
  visibility: visible;
}
.alert-wrapper.active .alert-service .service-inner {
  transform: none;
}
.modal-service-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.5);
  z-index: 555;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
.modal-service-wrapper.active {
  opacity: 1;
  visibility: visible;
}
.modal-service-wrapper .modal-service {
  padding: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
}
.modal-service-wrapper .modal-service .service-inner {
  background: #fff;
  width: 700px;
  max-width: 100%;
  padding: 50px 25px 25px 25px;
  text-align: center;
  position: relative;
}
.modal-service-wrapper .modal-service .service-inner .service-button {
  position: absolute;
  top: 20px;
  right: 20px;
  border: none;
  background: none;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-service-wrapper .modal-service .service-inner .service-button .button-bar {
  display: block;
  position: absolute;
  width: 20px;
  height: 2px;
  background: #222;
}
.modal-service-wrapper .modal-service .service-inner .service-button .button-bar:nth-child(1) {
  transform: rotate(45deg);
}
.modal-service-wrapper .modal-service .service-inner .service-button .button-bar:nth-child(2) {
  transform: rotate(-45deg);
}
.modal-service-wrapper .modal-service .service-inner .service-content .heading {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.modal-service-wrapper .modal-service .service-inner .service-content .btn {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}
.modal-service-wrapper .modal-service .service-inner .service-buttons {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal-service-wrapper .modal-service .service-inner .service-buttons .btn-link {
  padding: 0;
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
}
.section-heading {
  position: relative;
  height: 250px;
}
.section-heading .container {
  height: 100%;
}
.section-heading .heading-wrapper {
  display: flex;
  justify-content: space-between;
  height: 100%;
}
.section-heading .heading-wrapper .heading-left {
  position: relative;
  width: 25%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  text-align: right;
  padding-top: 30px;
}
.section-heading .heading-wrapper .heading-left .bg-color {
  position: absolute;
  background: url(../img/backgrounds/papyrus-blue.png);
  height: 250px;
  width: 2000px;
  top: 0;
  right: -15%;
  z-index: -1;
}
.section-heading .heading-wrapper .heading-left .bg-color .wave-1 {
  position: absolute;
  height: 15px;
  right: 10%;
  bottom: 0;
  z-index: 111;
  transform: rotate(35deg);
}
.section-heading .heading-wrapper .heading-left h1 {
  font-weight: 600;
  font-size: 50px;
  line-height: 50px;
  color: #fff;
}
.section-heading .heading-wrapper .heading-right {
  width: 75%;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 40px;
  transform: translatex(95px);
}
.section-heading .heading-wrapper .heading-right h2 {
  color: #444444;
  font-size: 30px;
}
.nav-button {
  margin-left: -10px;
  padding: 10px;
  border: none;
  background: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
}
.nav-button.hidden {
  display: none;
}
.nav-button.nav-button-welcome {
  margin-right: 30px;
}
.nav-button.nav-button-welcome .button-bar {
  background-color: transparent;
}
.nav-button .button-bar {
  height: 5px;
  border: 1px solid #222;
  background-color: #fff;
  width: 24px;
  border-radius: 10px;
  transition: all 0.4s;
}
.nav-button .button-bar:nth-child(2) {
  margin-top: 2px;
}
.nav-button .button-bar:nth-child(3) {
  margin-top: 2px;
}
.nav-button:hover .button-bar,
.nav-button:focus .button-bar {
  background: #222;
}
.nav-button:hover .button-bar:nth-child(2),
.nav-button:focus .button-bar:nth-child(2) {
  width: 20px;
  border-color: #0090ff;
  background: #0090ff;
}
nav.section-header {
  opacity: 1;
  visibility: visible;
  transition: all 0.3s;
}
nav.section-header.hidden {
  width: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
}
nav.section-header .header-flexbox {
  padding: 25px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
nav.section-header .header-flexbox .flexbox-logo img {
  height: 55px;
  width: auto;
}
nav.section-header .header-flexbox .flexbox-logo.hidden {
  display: none;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper {
  display: flex;
  justify-content: flex-end;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper.nav-welcome {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background: #fff;
  z-index: 555;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper.nav-welcome .nav-button-close {
  display: block;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper.nav-welcome .flexbox-nav-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
  padding: 50px;
  margin-right: 0;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper.nav-welcome .flexbox-nav-inner .flexbox-nav {
  display: flex;
  flex-direction: column;
  align-items: center;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper.nav-welcome .flexbox-nav-inner .flexbox-nav li {
  padding: 10px 0;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper.nav-welcome .flexbox-nav-inner .flexbox-nav li a {
  font-size: 16px;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper.nav-welcome.active {
  opacity: 1;
  visibility: visible;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close {
  position: absolute;
  display: none;
  width: 100%;
  top: 30px;
  left: 0;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close.hidden {
  display: none;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close .container {
  display: flex;
  justify-content: flex-end;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close .container:before,
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close .container:after {
  content: none;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button {
  outline: none;
  border: none;
  background: none;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button .button-bar {
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  background: #222;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button .button-bar:nth-child(1) {
  transform: rotate(45deg);
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button .button-bar:nth-child(2) {
  transform: rotate(-45deg);
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner {
  width: 100%;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li {
  padding: 0 20px;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a {
  color: #444444;
  font-size: 13px;
  transition: color 350ms;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a:hover {
  color: #0090ff;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a.active {
  color: #0090ff;
  font-weight: 700;
}
nav.section-header .header-flexbox .flexbox-contact {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
nav.section-header .header-flexbox .flexbox-contact.hidden {
  display: none;
}
nav.section-header .header-flexbox .flexbox-contact a {
  color: #444444;
  padding: 0 20px;
}
nav.section-header .header-flexbox .flexbox-contact a span {
  color: #444444;
  font-size: 13px;
  font-size: 12px;
  opacity: 1;
  transition: color 350ms;
}
nav.section-header .header-flexbox .flexbox-contact a img {
  width: 20px;
  margin: 0 5px;
}
nav.section-header .header-flexbox .flexbox-contact a:hover span {
  color: #0090ff;
}
nav.section-header .header-flexbox .flexbox-contact span {
  color: #444444;
  opacity: 0.4;
}
section.section-welcome {
  min-height: 100vh;
  position: relative;
}
section.section-welcome .welcome-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
}
section.section-welcome .welcome-background .background-content {
  width: 60%;
  position: relative;
}
section.section-welcome .welcome-background .background-content .content-color {
  background: radial-gradient(circle, #ffffff 0%, #dbdbdd 100%);
  width: 180%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
}
section.section-welcome .welcome-background .background-image {
  width: 40%;
  position: relative;
}
section.section-welcome .welcome-background .background-image .image-inner {
  position: absolute;
  bottom: -220px;
  left: 0;
  width: 100%;
}
section.section-welcome .welcome-background .background-image .image-inner .inner-box {
  margin: 0 auto;
  width: 60%;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  padding: 40px;
  position: relative;
}
section.section-welcome .welcome-background .background-image .image-inner .inner-box .box-image {
  position: absolute;
  top: 50px;
  right: 0;
  overflow: hidden;
}
section.section-welcome .welcome-background .background-image .image-inner .inner-box .box-image svg {
  width: 200px;
  height: 200px;
  fill: #0090ff;
  opacity: 0.5;
  transform: translateX(35%);
}
section.section-welcome .welcome-background .background-image .image-inner .inner-box .heading {
  font-size: 17px;
  font-weight: 600;
  position: relative;
}
section.section-welcome .welcome-background .background-image .image-inner .inner-box .btn-blue {
  margin-top: 30px;
  margin-bottom: 80px;
  font-weight: 700;
  white-space: nowrap;
  margin-left: -225px;
  position: relative;
}
section.section-welcome .welcome-background .background-image .image-inner .inner-box .btn-blue .btn-inner {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
section.section-welcome .welcome-background .background-image .image-inner .inner-box .btn-blue .btn-inner svg {
  height: 15px;
  min-width: 15px;
  max-width: 15px;
  margin-right: 15px;
  fill: #fff;
  transform: rotate(180deg);
  transition: all 0.4s;
}
section.section-welcome .welcome-background .background-image .image-inner .inner-box .btn-blue:hover .btn-inner svg {
  margin-right: 20px;
}
section.section-welcome .welcome-background .background-image .image-inner .inner-box ul {
  margin-top: 20px;
}
section.section-welcome .welcome-background .background-image .image-inner .inner-box ul li {
  margin-top: 5px;
}
section.section-welcome .welcome-background .background-image .image-inner .inner-box ul li .link {
  font-weight: 700;
  font-size: 17px;
  color: #0090ff;
}
section.section-welcome .welcome-background .background-image .image-inner .inner-box ul li .btn {
  margin-top: 15px;
}
section.section-welcome .welcome-inner {
  padding: 30px 0;
  width: 55%;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
section.section-welcome .welcome-inner .inner-flexbox {
  display: flex;
}
section.section-welcome .welcome-inner .inner-flexbox .inner-logo img {
  height: 55px;
  width: auto;
}
section.section-welcome .welcome-inner .inner-description h1 {
  font-size: 60px;
  font-weight: 800;
  line-height: 80px;
}
section.section-welcome .welcome-inner .inner-description ul {
  list-style: none;
  margin-top: 50px;
  padding-left: 50px;
}
section.section-welcome .welcome-inner .inner-description ul li {
  font-size: 16px;
  line-height: 26px;
  font-weight: 700;
}
section.section-welcome .welcome-inner .inner-description ul li a {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #444;
  transform-origin: left center;
  transition: all 0.4s;
}
section.section-welcome .welcome-inner .inner-description ul li a svg {
  margin-right: 15px;
  height: 15px;
  min-width: 15px;
  max-width: 15px;
  transform: rotate(180deg);
  fill: #0090ff;
  transition: all 0.4s;
}
section.section-welcome .welcome-inner .inner-description ul li a:hover,
section.section-welcome .welcome-inner .inner-description ul li a.active {
  color: #0090ff;
  transform: scale(1.1);
}
section.section-welcome .welcome-inner .inner-description ul li a:hover svg,
section.section-welcome .welcome-inner .inner-description ul li a.active svg {
  transform: rotate(180deg) scale(1.2) translate(-4px, 0);
  opacity: 1;
}
section.section-welcome .welcome-inner .inner-description ul:hover a,
section.section-welcome .welcome-inner .inner-description ul.active a {
  transform: scale(0.9);
}
section.section-welcome .welcome-inner .inner-description ul:hover svg,
section.section-welcome .welcome-inner .inner-description ul.active svg {
  opacity: 0;
}
section.section-welcome .welcome-inner .inner-description .btn {
  margin-top: 30px;
}
section.section-welcome .welcome-inner .inner-social-media {
  display: inline-block;
  margin-bottom: 20px;
}
section.section-welcome .welcome-inner .inner-social-media a {
  display: flex;
  align-items: center;
}
section.section-welcome .welcome-inner .inner-social-media a img {
  height: 20px;
}
section.section-welcome .welcome-inner .inner-social-media a .divider {
  display: block;
  height: 20px;
  width: 1px;
  border: 0.5px solid #ccc;
  margin: 0 20px;
}
section.section-welcome .welcome-inner .inner-social-media a p {
  color: #a9a9a9;
  transition: all 0.4s;
}
section.section-welcome .welcome-inner .inner-social-media a:hover p {
  color: #0090ff;
}
section.section-faq {
  padding: 60px 0 70px 0;
}
section.section-faq .owl-carousel-wrapper {
  padding-right: 36.5%;
}
section.section-faq .owl-carousel-wrapper .owl-carousel {
  padding-top: 70px;
}
section.section-faq .owl-carousel-wrapper .owl-stage-outer:hover .item {
  opacity: 0.3;
}
section.section-faq .owl-carousel-wrapper .faq-wrapper {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 9;
}
section.section-faq .owl-carousel-wrapper .faq-wrapper .item {
  transition: all 0.4s;
  display: block;
}
section.section-faq .owl-carousel-wrapper .faq-wrapper .item .heading {
  color: #000;
  display: block;
  font-size: 11px;
  font-weight: 600;
}
section.section-faq .owl-carousel-wrapper .faq-wrapper .item .heading:after {
  content: '';
  height: 3px;
  width: 100px;
  display: block;
  background: #000;
  margin: 15px 0;
}
section.section-faq .owl-carousel-wrapper .faq-wrapper .item h3 {
  font-size: 17px;
  color: #000;
}
section.section-faq .owl-carousel-wrapper .faq-wrapper .item p {
  margin-top: 20px;
  font-size: 13px;
  color: #000;
  text-overflow: ellipsis;
}
section.section-faq .owl-carousel-wrapper .faq-wrapper .item:hover {
  opacity: 1 !important;
}
section.section-faq .owl-carousel-wrapper .owl-controls {
  position: absolute;
  left: 0;
  top: 0;
}
section.section-faq .owl-carousel-wrapper .owl-controls .owl-nav {
  position: relative;
  display: flex;
  margin-top: 0;
}
section.section-faq .owl-carousel-wrapper .owl-controls .owl-nav:hover .owl-prev,
section.section-faq .owl-carousel-wrapper .owl-controls .owl-nav:hover .owl-next {
  opacity: 0.3;
}
section.section-faq .owl-carousel-wrapper .owl-controls .owl-nav .owl-prev,
section.section-faq .owl-carousel-wrapper .owl-controls .owl-nav .owl-next {
  background: url(../img/icons/down-chevron.svg) center;
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 0;
  margin: 0;
  height: 15px;
  width: 15px;
  transition: all 0.3s;
}
section.section-faq .owl-carousel-wrapper .owl-controls .owl-nav .owl-prev:hover,
section.section-faq .owl-carousel-wrapper .owl-controls .owl-nav .owl-next:hover {
  opacity: 1;
}
section.section-faq .owl-carousel-wrapper .owl-controls .owl-nav .owl-prev {
  transform: rotate(90deg);
}
section.section-faq .owl-carousel-wrapper .owl-controls .owl-nav .owl-next {
  transform: rotate(-90deg);
  margin-left: 20px;
}
section.section-rodo .rodo-flexbox {
  display: flex;
  position: relative;
}
section.section-rodo .rodo-flexbox .wave-2 {
  position: absolute;
  height: 15px;
  left: 40%;
  top: 90%;
  transform: translateX(-60%) rotate(-10deg);
  z-index: 111;
}
section.section-rodo .rodo-flexbox .wave-3 {
  position: absolute;
  height: 15px;
  right: 0;
  top: 15%;
  z-index: 111;
  transform: translateX(15%) rotate(15deg);
}
section.section-rodo .rodo-flexbox .flexbox-description {
  position: relative;
  padding: 40px;
  background: url(../img/backgrounds/papyrus.png);
  width: 40%;
}
section.section-rodo .rodo-flexbox .flexbox-description h2 {
  font-size: 32px;
  font-weight: 800;
}
section.section-rodo .rodo-flexbox .flexbox-description p {
  margin-top: 20px;
  font-size: 14px;
  color: #000;
  width: 90%;
  line-height: 22px;
}
section.section-rodo .rodo-flexbox .flexbox-description .btn {
  margin-top: 40px;
}
section.section-rodo .rodo-flexbox .flexbox-image {
  width: 60%;
  position: relative;
}
section.section-tagline {
  padding: 70px 0;
}
section.section-tagline .owl-carousel .item {
  display: flex;
}
section.section-tagline .owl-carousel .item .item-service {
  width: 20%;
  padding: 10px;
  text-align: center;
}
section.section-tagline .owl-carousel .item .item-service p {
  font-size: 13px;
  font-weight: 600;
  color: #000;
}
section.section-tagline .owl-carousel .item .item-service.active {
  border: 0.5px solid #999;
}
section.section-tagline .owl-carousel .owl-controls {
  display: none;
}
section.section-iod {
  padding-bottom: 70px;
}
section.section-iod .iod-flexbox {
  display: flex;
  justify-content: space-between;
  position: relative;
}
section.section-iod .iod-flexbox .flexbox-dot {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 111;
}
section.section-iod .iod-flexbox .flexbox-dot svg {
  height: 32px;
  min-width: 32px;
  max-width: 32px;
  fill: #0090ff;
}
section.section-iod .iod-flexbox .flexbox-description {
  width: 48.5%;
  padding: 40px 80px 40px 40px;
  background: #f4f4f4;
}
section.section-iod .iod-flexbox .flexbox-description h2 {
  font-size: 26px;
  font-weight: 700;
  line-height: 33px;
  color: #000;
}
section.section-iod .iod-flexbox .flexbox-description p {
  margin-top: 20px;
  font-size: 14px;
  color: #000;
  width: 90%;
  line-height: 22px;
}
section.section-iod .iod-flexbox .flexbox-description .btn {
  margin-top: 40px;
}
section.section-iod .iod-flexbox .flexbox-image {
  width: 48.5%;
  background: url(../img/backgrounds/papyrus-blue.png);
  padding: 50px 40px 40px 40px;
  display: flex;
  justify-content: flex-end;
  position: relative;
}
section.section-iod .iod-flexbox .flexbox-image .wave-1 {
  position: absolute;
  height: 15px;
  right: -4%;
  bottom: 15%;
  z-index: 111;
  transform: rotate(25deg);
}
section.section-iod .iod-flexbox .flexbox-image .wave-2 {
  position: absolute;
  height: 15px;
  left: 20%;
  top: 72%;
  z-index: 111;
  transform: rotate(-35deg);
}
section.section-iod .iod-flexbox .flexbox-image .image-logo {
  height: 40px;
}
section.static-page-section-content {
  padding: 30px 0 20px 0;
  position: relative;
  overflow: hidden;
}
section.static-page-section-content .wave-3 {
  position: absolute;
  height: 25px;
  right: 0;
  bottom: 45%;
  z-index: -1;
  transform: translateX(15%) rotate(-25deg);
}
section.static-page-section-content .content-cta {
  padding-bottom: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
section.static-page-section-content .content-cta .heading-cta {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 40%;
  text-align: left;
}
section.static-page-section-content .content-cta .heading-cta h4 {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 700;
}
section.static-page-section-content .content-cta .heading-cta h4 span {
  color: #0090ff;
}
section.static-page-section-content .content-cta .heading-cta p {
  color: #000;
  font-size: 14px;
}
section.static-page-section-content .content-cta .contact-cta {
  width: 40%;
  height: 100px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
}
section.static-page-section-content .content-cta .contact-cta .wave-4 {
  position: absolute;
  height: 15px;
  right: 0;
  bottom: -50%;
  fill: #0090ff;
  z-index: 111;
  transform: rotate(35deg);
}
section.static-page-section-content .content-cta .contact-cta .cta-bg {
  height: 200px;
  width: 2000px;
  position: absolute;
  left: -10%;
}
section.static-page-section-content .content-cta .contact-cta .cta-bg.bg-gray {
  background-color: #f4f4f4;
}
section.static-page-section-content .content-cta .contact-cta .cta-bg.bg-wave {
  background: url(../img/backgrounds/papyrus.png);
}
section.static-page-section-content .content-cta .contact-cta a:first-of-type {
  transform: translatex(-118px);
}
section.static-page-section-content .content-cta .contact-cta a:nth-of-type(2) {
  transform: translatex(60px);
}
section.static-page-section-content .page-inner {
  display: flex;
  transform: translatey(-75px);
}
section.static-page-section-content .page-inner .box-hidden {
  width: 34%;
}
section.static-page-section-content .page-inner .box-hidden.box-hidden-content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-right: 80px;
}
section.static-page-section-content .page-inner .box-hidden.box-hidden-content h4 {
  text-align: left;
  font-size: 24px;
  font-weight: 800;
}
section.static-page-section-content .page-inner .box-hidden.box-hidden-content h4:nth-of-type(even) {
  text-align: right;
}
section.static-page-section-content .page-inner .page-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 67%;
}
section.static-page-section-content .page-inner .page-content .content-description p {
  margin-top: 20px;
  font-size: 14px;
  color: #000;
  text-align: justify;
}
section.static-page-section-content .page-inner .page-content .content-description h3 {
  margin: 40px 0 20px 0;
  font-size: 26px;
  font-weight: 700;
  text-align: right;
  line-height: 36px;
}
section.static-page-section-content .page-inner .page-content .content-description ul {
  margin-top: 10px;
  list-style-type: none;
  padding-left: 25px;
  text-align: justify;
}
section.static-page-section-content .page-inner .page-content .content-description ul li {
  font-size: 14px;
  color: #000;
}
section.static-page-section-content .page-inner .page-content .content-description ul li:before {
  display: block;
  content: url(../img/icons/arrow.svg);
  height: 15px;
  min-width: 15px;
  max-width: 15px;
  fill: #0090ff;
  transition: all 350ms;
  transform: translate(-25px, 18px);
}
section.static-page-section-content .page-inner .page-content .content-description ul li:hover:before {
  transform: translate(-28px, 18px);
}
section.static-page-section-content .page-inner .page-content .content-description ul li a {
  color: #0090ff;
  text-decoration: underline !important;
  font-weight: 600;
  transition: color 350ms;
}
section.static-page-section-content .page-inner .page-content .content-description ul li a:hover {
  color: #000;
}
section.static-page-section-content .page-inner .page-content .faq-item {
  width: 100%;
}
section.static-page-section-content .page-inner .page-content .faq-item:not(:first-of-type) {
  padding-top: 40px;
}
section.static-page-section-content .page-inner .page-content .faq-item .heading {
  color: #000;
  display: block;
  font-size: 11px;
  font-weight: 600;
  display: inline-block;
}
section.static-page-section-content .page-inner .page-content .faq-item .heading:after {
  content: '';
  height: 3.5px;
  width: 100px;
  display: block;
  background: #000;
  margin-top: 15px;
}
section.static-page-section-content .page-inner .page-content .faq-item .flexbox-description {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
section.static-page-section-content .page-inner .page-content .faq-item .flexbox-description h3 {
  font-size: 19px;
  color: #000;
  margin-top: 15px;
  line-height: 30px;
}
section.static-page-section-content .page-inner .page-content .faq-item .flexbox-description p {
  font-size: 13px;
  color: #000;
  line-height: 24px;
  margin-top: 10px;
}
section.section-kontakt {
  padding: 40px 0 80px;
  overflow: hidden;
}
section.section-kontakt .section-wrapper {
  display: flex;
  justify-content: space-between;
}
section.section-kontakt .section-wrapper .box-contact {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
section.section-kontakt .section-wrapper .box-contact h3 {
  color: #000;
  font-weight: 800;
  font-size: 28px;
  line-height: 1.2;
}
section.section-kontakt .section-wrapper .box-contact address ul {
  margin-top: 20px;
}
section.section-kontakt .section-wrapper .box-contact address ul li {
  font-weight: 600;
  margin-top: 5px;
}
section.section-kontakt .section-wrapper .box-contact address ul li .link {
  font-weight: 600;
  color: #0090ff;
  transition: color 350ms;
}
section.section-kontakt .section-wrapper .box-contact address ul li .link:hover {
  color: #000;
}
section.section-kontakt .section-wrapper .map-wrapper {
  position: relative;
  width: 58%;
  height: 450px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
section.section-kontakt .section-wrapper .map-wrapper .map-bg {
  background: url(../img/backgrounds/papyrus.png);
  position: absolute;
  width: 2000px;
  left: -10%;
  height: 100%;
  z-index: -1;
}
section.section-kontakt .section-wrapper .map-wrapper .wave-2 {
  position: absolute;
  height: 15px;
  left: -7%;
  top: 85%;
  transform: translateX(-60%) rotate(-10deg);
  z-index: 111;
}
section.section-kontakt .section-wrapper .map-wrapper iframe {
  position: absolute;
  width: 100%;
  height: 80%;
  top: 10%;
  left: 5%;
}
section.section-footer {
  background: #f5f5f5;
  position: relative;
}
section.section-footer .footer-slogan {
  background: url(../img/backgrounds/papyrus-blue.png);
  padding: 17px 0;
  text-align: center;
}
section.section-footer .footer-slogan p {
  font-size: 14px;
  font-weight: 700;
  color: #fff;
}
section.section-footer .footer-flexbox {
  display: flex;
  justify-content: space-between;
  position: relative;
}
section.section-footer .footer-flexbox .flexbox-service {
  margin-top: 75px;
}
section.section-footer .footer-flexbox .flexbox-service.service-about .service-logo img {
  height: 55px;
}
section.section-footer .footer-flexbox .flexbox-service.service-about .service-description {
  margin-top: 20px;
}
section.section-footer .footer-flexbox .flexbox-service.service-about .service-description p {
  color: #444;
  font-size: 14px;
}
section.section-footer .footer-flexbox .flexbox-service ul li {
  margin-top: 5px;
}
section.section-footer .footer-flexbox .flexbox-service ul li.heading {
  margin-top: 0;
  padding-bottom: 10px;
  font-size: 18px;
  color: #444;
}
section.section-footer .footer-flexbox .flexbox-service ul li a {
  font-size: 13px;
  color: #444;
  transition: all 0.3s;
}
section.section-footer .footer-flexbox .flexbox-service ul li a b {
  font-size: 16px;
}
section.section-footer .footer-flexbox .flexbox-service ul li a span {
  font-size: 10px;
}
section.section-footer .footer-flexbox .flexbox-service ul li a:hover {
  color: #0090ff;
}
section.section-footer .footer-flexbox .flexbox-service ul li p {
  font-size: 12px;
  color: #444;
}
section.section-footer .footer-flexbox .flexbox-service ul li p b {
  font-weight: 400;
  font-size: 14px;
}
section.section-footer .footer-flexbox .flexbox-service ul li p span {
  font-size: 10px;
}
section.section-footer .footer-flexbox .flexbox-service .list-social {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
section.section-footer .footer-flexbox .flexbox-service .list-social li {
  margin-right: 20px;
}
section.section-footer .footer-flexbox .flexbox-service .list-social li a img {
  height: 25px;
}
section.section-footer .footer-flexbox .flexbox-service .list-social li:last-child {
  margin-right: 0;
}
section.section-footer .alert {
  position: relative;
  background: none;
  border: none;
  border-radius: 0;
  margin-top: 55px;
  padding: 0;
}
section.section-footer .alert p {
  font-size: 14px;
  color: #444;
  font-weight: 300;
}
section.section-footer .alert .alert-links {
  margin-top: 10px;
}
section.section-footer .alert .alert-links a {
  color: #444444;
  cursor: pointer;
  transition: all 0.3s;
  margin-right: 10px;
  font-size: 13px;
  font-weight: 500;
}
section.section-footer .alert .alert-links a:hover {
  color: #0090ff;
}
section.section-footer .footer-credits-wrapper {
  margin-top: 40px;
  padding: 25px 0;
  background: #e5e5e5;
}
section.section-footer .footer-credits-wrapper .footer-credits {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
section.section-footer .footer-credits-wrapper .footer-credits p {
  font-size: 13px;
  color: #444;
  font-weight: 300;
}
section.section-footer .footer-credits-wrapper .footer-credits p a {
  color: #0090ff;
  font-weight: 600;
}
section.section-footer .footer-credits-wrapper .footer-credits .footer-payment {
  display: flex;
  align-items: center;
}
section.section-footer .footer-credits-wrapper .footer-credits .footer-payment p {
  margin-right: 15px;
}
section.section-footer .footer-credits-wrapper .footer-credits .footer-payment img {
  margin-right: 10px;
  height: 19px;
}
section.section-footer .footer-credits-wrapper .footer-credits .footer-payment img:last-child {
  margin-right: 0;
}
@media (max-width: 1200.98px) {
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li {
    padding: 0 10px;
  }
  .section-heading .heading-wrapper .heading-left.md-small h1 {
    font-size: 38px;
  }
  .section-heading .heading-wrapper .heading-right {
    transform: translatex(80px);
  }
  section.section-welcome .welcome-background .background-image .image-inner .inner-box {
    width: 85%;
  }
  section.static-page-section-content .wave-3 {
    right: -3%;
  }
}
@media (max-width: 991.98px) {
  .section-heading .heading-wrapper .heading-left .bg-color {
    right: -20%;
  }
  .section-heading .heading-wrapper .heading-right {
    transform: translatex(60px);
  }
  nav.section-header .header-flexbox .flexbox-logo img {
    height: 45px;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a {
    font-size: 12px;
  }
  nav.section-header .header-flexbox .flexbox-contact a {
    font-weight: 600;
    font-size: 12px;
    padding: 0 5px;
  }
  nav.section-header .header-flexbox .flexbox-contact a span {
    display: none;
  }
  section.section-welcome .welcome-background .background-image .image-inner {
    bottom: -50px;
    left: -50px;
  }
  section.section-welcome .welcome-background .background-image .image-inner .inner-box {
    width: 100%;
  }
  section.section-welcome .welcome-background .background-image .image-inner .inner-box .btn-blue {
    display: none;
  }
  section.section-welcome .welcome-background .background-image .image-inner .inner-box ul li .link {
    font-size: 15px;
  }
  section.section-welcome .welcome-background .background-image .image-inner .inner-box .box-image svg {
    transform: translateX(50%);
    width: 150px;
    height: 150px;
  }
  section.section-welcome .welcome-background .background-content .content-color {
    width: 100%;
  }
  section.section-welcome .welcome-inner {
    width: 57%;
  }
  section.section-faq .owl-carousel-wrapper {
    padding-right: 0;
  }
  section.section-rodo .rodo-flexbox .flexbox-description {
    width: 60%;
  }
  section.section-rodo .rodo-flexbox .flexbox-image {
    width: 40%;
  }
  section.section-iod .iod-flexbox .flexbox-description {
    width: 56%;
  }
  section.section-iod .iod-flexbox .flexbox-image {
    width: 40%;
  }
  section.section-iod .iod-flexbox .flexbox-dot {
    transform: translate(20%, -50%);
  }
  section.static-page-section-content .content-cta .contact-cta .cta-bg {
    left: 5%;
  }
  section.static-page-section-content .content-cta .contact-cta a:first-of-type {
    transform: translatex(-60px);
  }
  section.static-page-section-content .content-cta .contact-cta .wave-4 {
    right: -25%;
  }
  section.section-footer .footer-slogan p {
    font-size: 13px;
  }
  section.section-footer .alert {
    margin-top: 30px;
  }
  section.section-footer .alert p {
    text-align: center;
    font-size: 12px;
  }
  section.section-footer .alert .alert-links {
    display: flex;
    justify-content: center;
  }
  section.section-footer .footer-flexbox {
    flex-direction: column;
    align-items: center;
  }
  section.section-footer .footer-flexbox .flexbox-service {
    text-align: center;
    margin-top: 30px;
  }
  section.section-footer .footer-flexbox .flexbox-service .list-social {
    justify-content: center;
  }
  section.section-footer .footer-credits-wrapper .footer-credits {
    flex-direction: column;
    text-align: center;
  }
  section.section-footer .footer-credits-wrapper .footer-credits p {
    font-size: 10px;
  }
  section.section-footer .footer-credits-wrapper .footer-credits .footer-payment {
    margin-top: 10px;
  }
  section.section-footer .footer-credits-wrapper .footer-credits .footer-payment p {
    font-size: 10px;
  }
}
@media (max-width: 767.98px) {
  .nav-button {
    display: flex;
    margin-right: -10px;
  }
  .nav-button.hidden {
    display: flex;
  }
  .nav-button.nav-button-welcome {
    display: none;
  }
  .nav-button .button-bar.button-bar-color {
    background-color: #dbdbdd;
  }
  nav.section-header {
    width: 100%;
  }
  nav.section-header.hidden {
    display: block;
    width: 100%;
    height: 100%;
    visibility: visible;
    opacity: 1;
  }
  nav.section-header .header-flexbox .flexbox-logo.hidden {
    display: block;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    background: #fff;
    z-index: 555;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close {
    display: block;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
    padding: 50px;
    margin-right: 0;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li {
    padding: 10px 0;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a {
    font-size: 16px;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper.active {
    opacity: 1;
    visibility: visible;
  }
  nav.section-header .header-flexbox .flexbox-contact.hidden {
    display: flex;
  }
  nav.section-header .header-flexbox .flexbox-contact a {
    font-weight: 600;
    font-size: 12px;
    padding: 0 10px;
  }
  nav.section-header .header-flexbox .flexbox-contact a span {
    display: block;
  }
  nav.section-header .header-flexbox .flexbox-contact a img {
    display: none;
  }
  .section-heading .heading-wrapper .heading-left .bg-color .wave-1 {
    right: 4%;
  }
  .section-heading .heading-wrapper .heading-left.md-small h1 {
    font-size: 32px;
    line-height: 40px;
  }
  .section-heading .heading-wrapper .heading-right.md-small h2 {
    font-size: 26px;
  }
  section.section-welcome .welcome-background {
    justify-content: space-between;
  }
  section.section-welcome .welcome-background .background-image {
    width: 25%;
  }
  section.section-welcome .welcome-background .background-image .image-inner {
    display: none;
  }
  section.section-welcome .welcome-background .background-content {
    width: 85%;
  }
  section.section-welcome .welcome-background .background-content .content-color {
    width: 100%;
  }
  section.section-welcome .welcome-inner {
    width: 100%;
  }
  section.section-welcome .welcome-inner .inner-flexbox .inner-logo {
    display: none;
  }
  section.section-welcome .welcome-inner .inner-social-media {
    margin-bottom: 70px;
  }
  section.section-iod .iod-flexbox,
  section.section-rodo .iod-flexbox,
  section.section-iod .rodo-flexbox,
  section.section-rodo .rodo-flexbox {
    flex-direction: column;
  }
  section.section-iod .iod-flexbox .wave-2,
  section.section-rodo .iod-flexbox .wave-2,
  section.section-iod .rodo-flexbox .wave-2,
  section.section-rodo .rodo-flexbox .wave-2 {
    left: 20%;
  }
  section.section-iod .iod-flexbox .flexbox-description,
  section.section-rodo .iod-flexbox .flexbox-description,
  section.section-iod .rodo-flexbox .flexbox-description,
  section.section-rodo .rodo-flexbox .flexbox-description {
    width: 100%;
    padding: 25px;
  }
  section.section-iod .iod-flexbox .flexbox-description .btn,
  section.section-rodo .iod-flexbox .flexbox-description .btn,
  section.section-iod .rodo-flexbox .flexbox-description .btn,
  section.section-rodo .rodo-flexbox .flexbox-description .btn {
    margin-top: 20px;
  }
  section.section-iod .iod-flexbox .flexbox-image,
  section.section-rodo .iod-flexbox .flexbox-image,
  section.section-iod .rodo-flexbox .flexbox-image,
  section.section-rodo .rodo-flexbox .flexbox-image {
    width: 100%;
    min-height: 100px;
    padding: 15px;
  }
  section.section-iod .iod-flexbox .flexbox-image .wave-1,
  section.section-rodo .iod-flexbox .flexbox-image .wave-1,
  section.section-iod .rodo-flexbox .flexbox-image .wave-1,
  section.section-rodo .rodo-flexbox .flexbox-image .wave-1 {
    display: none;
  }
  section.section-iod .iod-flexbox .flexbox-image .wave-2,
  section.section-rodo .iod-flexbox .flexbox-image .wave-2,
  section.section-iod .rodo-flexbox .flexbox-image .wave-2,
  section.section-rodo .rodo-flexbox .flexbox-image .wave-2 {
    top: 85%;
  }
  section.section-iod .iod-flexbox .flexbox-dot,
  section.section-rodo .iod-flexbox .flexbox-dot,
  section.section-iod .rodo-flexbox .flexbox-dot,
  section.section-rodo .rodo-flexbox .flexbox-dot {
    display: none;
  }
  section.section-tagline .owl-carousel .item .item-service {
    width: 100%;
  }
  section.static-page-section-content .content-cta .contact-cta {
    width: 47%;
  }
  section.static-page-section-content .content-cta .contact-cta .cta-bg {
    left: 25%;
  }
  section.static-page-section-content .content-cta .contact-cta a:first-of-type {
    transform: translatex(-22px);
  }
  section.section-kontakt .section-wrapper {
    flex-direction: column;
    align-items: center;
  }
  section.section-kontakt .section-wrapper .box-contact {
    width: 100%;
    align-items: center;
    text-align: center;
    padding-bottom: 20px;
  }
  section.section-kontakt .section-wrapper .map-wrapper {
    width: 100%;
  }
}
@media (max-width: 600px) {
  body .btn.btn-small {
    padding: 8px 15px;
  }
  .section-heading .heading-wrapper .heading-left {
    width: 45%;
    padding-top: 15px;
  }
  .section-heading .heading-wrapper .heading-left .bg-color {
    height: 160px;
    right: -15%;
  }
  .section-heading .heading-wrapper .heading-left .bg-color .wave-1 {
    height: 12px;
    right: 2%;
  }
  .section-heading .heading-wrapper .heading-left h1 {
    font-size: 40px;
  }
  .section-heading .heading-wrapper .heading-left.md-small h1 {
    font-size: 26px;
    line-height: 32px;
  }
  .section-heading .heading-wrapper .heading-right {
    width: 45%;
    padding-top: 20px;
    transform: translatex(0);
  }
  .section-heading .heading-wrapper .heading-right h2 {
    font-size: 20px;
  }
  .section-heading .heading-wrapper .heading-right.md-small h2 {
    font-size: 20px;
  }
  nav.section-header .header-flexbox .flexbox-logo img {
    height: 45px;
  }
  nav.section-header .header-flexbox .flexbox-contact {
    transform: translatex(-10px);
  }
  nav.section-header .header-flexbox .flexbox-contact a {
    padding: 0 5px;
    font-size: 10.5px;
  }
  section.section-welcome {
    min-height: auto;
  }
  section.section-welcome .welcome-background .background-image {
    display: none;
  }
  section.section-welcome .welcome-background .background-content {
    width: 100%;
  }
  section.section-welcome .welcome-inner {
    padding: 0;
  }
  section.section-welcome .welcome-inner .inner-description {
    transform: translateY(-30px);
    text-align: center;
  }
  section.section-welcome .welcome-inner .inner-description h1 {
    line-height: 50px;
    font-size: 40px;
  }
  section.section-welcome .welcome-inner .inner-description ul {
    padding-left: 0;
  }
  section.section-welcome .welcome-inner .inner-description ul a {
    font-size: 14px;
    text-align: left;
  }
  section.section-welcome .welcome-inner .inner-social-media {
    display: flex;
    justify-content: center;
  }
  section.section-welcome .welcome-inner .inner-social-media p {
    font-size: 15px;
  }
  section.section-iod,
  section.section-rodo {
    margin-top: 30px;
  }
  section.section-iod .iod-flexbox .flexbox-description,
  section.section-rodo .iod-flexbox .flexbox-description,
  section.section-iod .rodo-flexbox .flexbox-description,
  section.section-rodo .rodo-flexbox .flexbox-description {
    text-align: center;
  }
  section.section-iod .iod-flexbox .flexbox-description p,
  section.section-rodo .iod-flexbox .flexbox-description p,
  section.section-iod .rodo-flexbox .flexbox-description p,
  section.section-rodo .rodo-flexbox .flexbox-description p {
    width: 100%;
  }
  section.section-faq {
    padding: 50px 0 40px 0;
  }
  section.section-tagline {
    display: none;
  }
  section.static-page-section-content .wave-3 {
    display: none;
  }
  section.static-page-section-content .page-inner {
    flex-direction: column;
    align-items: center;
  }
  section.static-page-section-content .page-inner .box-hidden.box-hidden-content {
    width: 100%;
    padding-right: 0;
    padding-top: 20px;
    flex-direction: row;
    align-items: center;
  }
  section.static-page-section-content .page-inner .box-hidden.box-hidden-content h4:not(:last-of-type) {
    margin-right: 5px;
  }
  section.static-page-section-content .page-inner .page-content {
    width: 100%;
    padding-top: 20px;
  }
  section.static-page-section-content .page-inner .page-content .content-description h3 {
    font-size: 24px;
    line-height: 32px;
  }
  section.static-page-section-content .page-inner .page-content .faq-item:not(:first-of-type) {
    padding-top: 20px;
  }
}
@media (max-width: 400px) {
  .section-heading {
    height: 150px;
  }
  .section-heading .heading-wrapper .heading-left {
    padding-top: 10px;
  }
  .section-heading .heading-wrapper .heading-left .bg-color {
    height: 120px;
  }
  .section-heading .heading-wrapper .heading-left h1 {
    font-size: 30px;
  }
  .section-heading .heading-wrapper .heading-left.md-small h1 {
    font-size: 24px;
  }
  .section-heading .heading-wrapper .heading-right {
    padding-top: 22px;
  }
  .section-heading .heading-wrapper .heading-right h2 {
    font-size: 20px;
  }
  .section-heading .heading-wrapper .heading-right.md-small h2 {
    font-size: 18px;
  }
  section.static-page-section-content {
    padding: 20px 0 30px 0;
  }
  section.static-page-section-content .page-inner {
    transform: translatey(0);
  }
  section.static-page-section-content .page-inner .box-hidden.box-hidden-content h4 {
    font-size: 18px;
  }
  section.static-page-section-content .page-inner .page-content .content-description p {
    text-align: center;
  }
  section.static-page-section-content .page-inner .page-content .content-description ul {
    text-align: left;
  }
  section.static-page-section-content .content-cta {
    flex-direction: column;
    padding: 40px 0;
  }
  section.static-page-section-content .content-cta .heading-cta {
    width: 100%;
    text-align: center;
    margin-bottom: 60px;
  }
  section.static-page-section-content .content-cta .contact-cta {
    width: 100%;
  }
  section.static-page-section-content .content-cta .contact-cta .wave-4 {
    right: 0;
    bottom: -38%;
    height: 12px;
  }
  section.static-page-section-content .content-cta .contact-cta .cta-bg {
    left: 28%;
    height: 150px;
  }
  section.static-page-section-content .content-cta .contact-cta a:first-of-type {
    transform: translatex(18px);
  }
  section.static-page-section-content .content-cta .contact-cta a:nth-of-type(2) {
    transform: translatex(150px);
  }
  section.section-kontakt {
    padding: 20px 0 50px 0;
  }
  section.section-kontakt .section-wrapper .map-wrapper {
    height: 300px;
  }
  section.section-kontakt .section-wrapper .map-wrapper iframe {
    left: 2.5%;
    width: 300px;
  }
  section.section-kontakt .section-wrapper .map-wrapper .wave-2 {
    left: 15%;
    top: 90%;
    transform: translateX(-60%) rotate(-25deg);
  }
}
