@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800,900&display=swap&subset=latin-ext');

body {
	min-height: 100vh;
	height: 100%;
	font-family: 'Montserrat', sans-serif;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	overflow-x: hidden;

	&.no-scroll {
		overflow: hidden;
		max-height: 100vh;
		padding-right: 17px;
	}

	.btn {
		padding: 15px 35px;
		border-radius: 0;
		text-transform: uppercase;
		font-size: 11px;
		font-weight: 600;
		letter-spacing: 1px;
		transition: all 0.3s;
		white-space: normal;

		img {
			margin-left: 15px;
			height: 10px;
		}

		&.btn-default {
			background: none;
			color: #222;
			border: 1px solid #222;

			&:hover {
				background: #222;
				border-color: #222;
				color: #fff;
			}
		}

		&.btn-blue {
			background: @color1;
			color: #fff;
			border: 1px solid @color1;

			&:hover {
				background: darken(@color1, 5%);
				border-color: darken(@color1, 5%);
			}
		}

		&.btn-small {
			padding: 5px 15px;
			font-size: 10px;
		}
	}

	ul,
	ol,
	p,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin: 0;
		padding: 0;
	}

	a {
		text-decoration: none !important;
	}
}

@color1: #0090ff;

.alert-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	overflow-y: scroll;
	background: rgba(0, 0, 0, 0.2);
	z-index: 555;
	opacity: 0;
	visibility: hidden;
	transition: all 0.4s;

	.alert-service {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		min-height: 100vh;
		width: 100%;
		transform: translateX(8px);
		padding: 50px 0;

		.service-inner {
			padding: 50px;
			background: #fff;
			width: 100%;
			box-shadow: 0px 0px 32px 1px rgba(0, 0, 0, 0.15);
			text-align: center;
			border-top: 5px solid #fff;
			position: relative;
			transform: scale(0.8);
			transition: all 0.5s;

			.inner-dismiss {
				top: 10px;
				right: 10px;
				position: absolute;
				border: 0;
				background: none;
				padding: 0;
				cursor: pointer;
				display: flex;
				padding: 5px;
				outline: none;

				img {
					height: 15px;
				}
			}

			&.success {
				border-top: 5px solid #78b042;
			}

			&.warning {
				border-top: 5px solid #d51a27;
			}

			&.info {
				border-top: 5px solid #1a88d5;
			}
		}
	}

	&.active {
		opacity: 1;
		visibility: visible;

		.alert-service {
			.service-inner {
				transform: none;
			}
		}
	}
}

.modal-service-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	overflow-y: scroll;
	background: rgba(0, 0, 0, 0.5);
	z-index: 555;
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s;

	&.active {
		opacity: 1;
		visibility: visible;
	}

	.modal-service {
		padding: 50px;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 100%;

		.service-inner {
			background: #fff;
			width: 700px;
			max-width: 100%;
			padding: 50px 25px 25px 25px;
			text-align: center;
			position: relative;

			.service-button {
				position: absolute;
				top: 20px;
				right: 20px;
				border: none;
				background: none;
				padding: 15px;
				display: flex;
				justify-content: center;
				align-items: center;

				.button-bar {
					display: block;
					position: absolute;
					width: 20px;
					height: 2px;
					background: #222;

					&:nth-child(1) {
						transform: rotate(45deg);
					}

					&:nth-child(2) {
						transform: rotate(-45deg);
					}
				}
			}

			.service-content {
				.heading {
					font-size: 16px;
					font-weight: 700;
					text-transform: uppercase;
					margin-bottom: 20px;
				}

				.btn {
					display: flex;
					justify-content: center;
					margin-top: 5px;
				}
			}

			.service-buttons {
				margin-top: 30px;
				display: flex;
				justify-content: space-between;
				align-items: center;

				.btn-link {
					padding: 0;
					text-transform: uppercase;
					font-weight: 700;
					text-decoration: none;
				}
			}
		}
	}
}

.section-heading {
	position: relative;
	height: 250px;

	.container {
		height: 100%;
	}

	.heading-wrapper {
		display: flex;
		justify-content: space-between;
		height: 100%;

		.heading-left {
			position: relative;
			width: 25%;
			display: flex;
			align-items: flex-start;
			justify-content: flex-end;
			text-align: right;
			padding-top: 30px;

			.bg-color {
				position: absolute;
				background: url(../img/backgrounds/papyrus-blue.png);
				height: 250px;
				width: 2000px;
				top: 0;
				right: -15%;
				z-index: -1;

				.wave-1 {
					position: absolute;
					height: 15px;
					right: 10%;
					bottom: 0;
					z-index: 111;
					transform: rotate(35deg);
				}
			}

			h1 {
				font-weight: 600;
				font-size: 50px;
				line-height: 50px;
				color: #fff;
			}
		}

		.heading-right {
			width: 75%;
			text-align: left;
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;
			padding-top: 40px;
			transform: translatex(95px);

			h2 {
				color: #444444;
				font-size: 30px;
			}
		}
	}
}

.nav-button {
	margin-left: -10px;
	padding: 10px;
	border: none;
	background: none;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	outline: none;
	cursor: pointer;

	&.hidden {
		display: none;
	}

	&.nav-button-welcome {
		margin-right: 30px;

		.button-bar {
			background-color: transparent;
		}
	}

	.button-bar {
		height: 5px;
		border: 1px solid #222;
		background-color: #fff;
		width: 24px;
		border-radius: 10px;
		transition: all 0.4s;

		&:nth-child(2) {
			margin-top: 2px;
		}

		&:nth-child(3) {
			margin-top: 2px;
		}
	}

	&:hover,
	&:focus {
		.button-bar {
			background: #222;

			&:nth-child(2) {
				width: 20px;
				border-color: @color1;
				background: @color1;
			}
		}
	}
}

nav.section-header {
	opacity: 1;
	visibility: visible;
	transition: all 0.3s;

	&.hidden {
		width: 0;
		height: 0;
		opacity: 0;
		visibility: hidden;
	}

	.header-flexbox {
		padding: 25px 0;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.flexbox-logo {
			img {
				height: 55px;
				width: auto;
			}

			&.hidden {
				display: none;
			}
		}

		.flexbox-nav-wrapper {
			display: flex;
			justify-content: flex-end;

			&.nav-welcome {
				position: fixed;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				overflow-y: scroll;
				background: #fff;
				z-index: 555;
				opacity: 0;
				visibility: hidden;
				transition: all 0.3s;

				.nav-button-close {
					display: block;
				}

				.flexbox-nav-inner {
					display: flex;
					flex-direction: column;
					justify-content: center;
					min-height: 100%;
					padding: 50px;
					margin-right: 0;

					.flexbox-nav {
						display: flex;
						flex-direction: column;
						align-items: center;

						li {
							padding: 10px 0;

							a {
								font-size: 16px;
							}
						}
					}
				}

				&.active {
					opacity: 1;
					visibility: visible;
				}
			}

			.nav-button-close {
				position: absolute;
				display: none;
				width: 100%;
				top: 30px;
				left: 0;

				&.hidden {
					display: none;
				}

				.container {
					display: flex;
					justify-content: flex-end;

					&:before,
					&:after {
						content: none;
					}
				}

				button {
					outline: none;
					border: none;
					background: none;
					padding: 15px;
					display: flex;
					justify-content: center;
					align-items: center;
					cursor: pointer;

					.button-bar {
						display: block;
						position: absolute;
						width: 30px;
						height: 2px;
						background: #222;

						&:nth-child(1) {
							transform: rotate(45deg);
						}

						&:nth-child(2) {
							transform: rotate(-45deg);
						}
					}
				}
			}

			.flexbox-nav-inner {
				width: 100%;

				.flexbox-nav {
					width: 100%;
					display: flex;
					justify-content: space-between;

					li {
						padding: 0 20px;

						a {
							color: #444444;
							font-size: 13px;
							transition: color 350ms;

							&:hover {
								color: @color1;
							}

							&.active {
								color: @color1;
								font-weight: 700;
							}
						}
					}
				}
			}
		}

		.flexbox-contact {
			display: flex;
			justify-content: space-between;
			align-items: center;

			&.hidden {
				display: none;
			}

			a {
				color: #444444;
				padding: 0 20px;

				span {
					color: #444444;
					font-size: 13px;
					font-size: 12px;
					opacity: 1;
					transition: color 350ms;
				}

				img {
					width: 20px;
					margin: 0 5px;
				}

				&:hover span {
					color: @color1;
				}
			}

			span {
				color: #444444;
				opacity: 0.4;
			}
		}
	}
}

section.section-welcome {
	min-height: 100vh;
	position: relative;

	.welcome-background {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;

		.background-content {
			width: 60%;
			position: relative;

			.content-color {
				background: radial-gradient(circle,
						rgba(255, 255, 255, 1) 0%,
						rgba(219, 219, 221, 1) 100%);
				width: 180%;
				height: 100%;
				top: 0;
				left: 0;
				position: absolute;
			}
		}

		.background-image {
			width: 40%;
			position: relative;

			.image-inner {
				position: absolute;
				bottom: -220px;
				left: 0;
				width: 100%;

				.inner-box {
					margin: 0 auto;
					width: 60%;
					background: #fff;
					box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
					padding: 40px;
					position: relative;

					.box-image {
						position: absolute;
						top: 50px;
						right: 0;
						overflow: hidden;

						svg {
							width: 200px;
							height: 200px;
							fill: @color1;
							opacity: 0.5;
							transform: translateX(35%);
						}
					}

					.heading {
						font-size: 17px;
						font-weight: 600;
						position: relative;
					}

					.btn-blue {
						margin-top: 30px;
						margin-bottom: 80px;
						font-weight: 700;
						white-space: nowrap;
						margin-left: -225px;
						position: relative;

						.btn-inner {
							display: flex;
							align-items: center;
							justify-content: flex-start;

							svg {
								height: 15px;
								min-width: 15px;
								max-width: 15px;
								margin-right: 15px;
								fill: #fff;
								transform: rotate(180deg);
								transition: all 0.4s;
							}
						}

						&:hover {
							.btn-inner {
								svg {
									margin-right: 20px;
								}
							}
						}
					}

					ul {
						margin-top: 20px;

						li {
							margin-top: 5px;

							.link {
								font-weight: 700;
								font-size: 17px;
								color: @color1;
							}

							.btn {
								margin-top: 15px;
							}
						}
					}
				}
			}
		}
	}

	.welcome-inner {
		padding: 30px 0;
		width: 55%;
		min-height: 100vh;
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.inner-flexbox {
			display: flex;

			.inner-logo {
				img {
					height: 55px;
					width: auto;
				}
			}
		}

		.inner-description {
			h1 {
				font-size: 60px;
				font-weight: 800;
				line-height: 80px;
			}

			ul {
				list-style: none;
				margin-top: 50px;
				padding-left: 50px;

				li {
					font-size: 16px;
					line-height: 26px;
					font-weight: 700;

					a {
						display: flex;
						align-items: center;
						justify-content: flex-start;
						color: #444;
						transform-origin: left center;
						transition: all 0.4s;

						svg {
							margin-right: 15px;
							height: 15px;
							min-width: 15px;
							max-width: 15px;
							transform: rotate(180deg);
							fill: @color1;
							transition: all 0.4s;
						}

						&:hover,
						&.active {
							color: @color1;
							transform: scale(1.1);

							svg {
								transform: rotate(180deg) scale(1.2) translate(-4px, 0);
								opacity: 1;
							}
						}
					}
				}

				&:hover,
				&.active {
					a {
						transform: scale(0.9);
					}

					svg {
						opacity: 0;
					}
				}
			}

			.btn {
				margin-top: 30px;
			}
		}

		.inner-social-media {
			display: inline-block;
			margin-bottom: 20px;

			a {
				display: flex;
				align-items: center;

				img {
					height: 20px;
				}

				.divider {
					display: block;
					height: 20px;
					width: 1px;
					border: 0.5px solid #ccc;
					margin: 0 20px;
				}

				p {
					color: #a9a9a9;
					transition: all 0.4s;
				}

				&:hover {
					p {
						color: @color1;
					}
				}
			}
		}
	}
}

section.section-faq {
	padding: 60px 0 70px 0;

	.owl-carousel-wrapper {
		padding-right: 36.5%;

		.owl-carousel {
			padding-top: 70px;
		}

		.owl-stage-outer {
			&:hover {
				.item {
					opacity: 0.3;
				}
			}
		}

		.faq-wrapper {
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 9;

			.item {
				transition: all 0.4s;
				display: block;

				.heading {
					color: #000;
					display: block;
					font-size: 11px;
					font-weight: 600;

					&:after {
						content: '';
						height: 3px;
						width: 100px;
						display: block;
						background: #000;
						margin: 15px 0;
					}
				}

				h3 {
					font-size: 17px;
					color: #000;
				}

				p {
					margin-top: 20px;
					font-size: 13px;
					color: #000;
					text-overflow: ellipsis;
				}

				&:hover {
					opacity: 1 !important;
				}
			}
		}

		.owl-controls {
			position: absolute;
			left: 0;
			top: 0;

			.owl-nav {
				position: relative;
				display: flex;
				margin-top: 0;

				&:hover {

					.owl-prev,
					.owl-next {
						opacity: 0.3;
					}
				}

				.owl-prev,
				.owl-next {
					background: url(../img/icons/down-chevron.svg) center;
					background-size: 100%;
					background-repeat: no-repeat;
					padding: 0;
					margin: 0;
					height: 15px;
					width: 15px;
					transition: all 0.3s;

					&:hover {
						opacity: 1;
					}
				}

				.owl-prev {
					transform: rotate(90deg);
				}

				.owl-next {
					transform: rotate(-90deg);
					margin-left: 20px;
				}
			}
		}
	}
}

section.section-rodo {
	.rodo-flexbox {
		display: flex;
		position: relative;

		.wave-2 {
			position: absolute;
			height: 15px;
			left: 40%;
			top: 90%;
			transform: translateX(-60%) rotate(-10deg);
			z-index: 111;
		}

		.wave-3 {
			position: absolute;
			height: 15px;
			right: 0;
			top: 15%;
			z-index: 111;
			transform: translateX(15%) rotate(15deg);
		}

		.flexbox-description {
			position: relative;
			padding: 40px;
			background: url(../img/backgrounds/papyrus.png);
			width: 40%;

			h2 {
				font-size: 32px;
				font-weight: 800;
			}

			p {
				margin-top: 20px;
				font-size: 14px;
				color: #000;
				width: 90%;
				line-height: 22px;
			}

			.btn {
				margin-top: 40px;
			}
		}

		.flexbox-image {
			width: 60%;
			position: relative;
		}
	}
}

section.section-tagline {
	padding: 70px 0;

	.owl-carousel {
		.item {
			display: flex;

			.item-service {
				width: 20%;
				padding: 10px;
				text-align: center;

				p {
					font-size: 13px;
					font-weight: 600;
					color: #000;
				}

				&.active {
					border: 0.5px solid #999;
				}
			}
		}

		.owl-controls {
			display: none;
		}
	}
}

section.section-iod {
	padding-bottom: 70px;

	.iod-flexbox {
		display: flex;
		justify-content: space-between;
		position: relative;

		.flexbox-dot {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			height: 80px;
			width: 80px;
			border-radius: 50%;
			background: #fff;
			display: flex;
			align-items: center;
			justify-content: center;
			z-index: 111;

			svg {
				height: 32px;
				min-width: 32px;
				max-width: 32px;
				fill: @color1;
			}
		}

		.flexbox-description {
			width: 48.5%;
			padding: 40px 80px 40px 40px;
			background: rgba(244, 244, 244, 1);

			h2 {
				font-size: 26px;
				font-weight: 700;
				line-height: 33px;
				color: #000;
			}

			p {
				margin-top: 20px;
				font-size: 14px;
				color: #000;
				width: 90%;
				line-height: 22px;
			}

			.btn {
				margin-top: 40px;
			}
		}

		.flexbox-image {
			width: 48.5%;
			background: url(../img/backgrounds/papyrus-blue.png);
			padding: 50px 40px 40px 40px;
			display: flex;
			justify-content: flex-end;
			position: relative;

			.wave-1 {
				position: absolute;
				height: 15px;
				right: -4%;
				bottom: 15%;
				z-index: 111;
				transform: rotate(25deg);
			}

			.wave-2 {
				position: absolute;
				height: 15px;
				left: 20%;
				top: 72%;
				z-index: 111;
				transform: rotate(-35deg);
			}

			.image-logo {
				height: 40px;
			}
		}
	}
}

section.static-page-section-content {
	padding: 30px 0 20px 0;
	position: relative;
	overflow: hidden;

	.wave-3 {
		position: absolute;
		height: 25px;
		right: 0;
		bottom: 45%;
		z-index: -1;
		transform: translateX(15%) rotate(-25deg);
	}

	.content-cta {
		padding-bottom: 80px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;

		.heading-cta {
			display: flex;
			flex-direction: column;
			justify-content: center;
			width: 40%;
			text-align: left;

			h4 {
				margin-bottom: 20px;
				font-size: 20px;
				font-weight: 700;

				span {
					color: @color1;
				}
			}

			p {
				color: #000;
				font-size: 14px;
			}
		}

		.contact-cta {
			width: 40%;
			height: 100px;
			position: relative;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: space-around;

			.wave-4 {
				position: absolute;
				height: 15px;
				right: 0;
				bottom: -50%;
				fill: @color1;
				z-index: 111;
				transform: rotate(35deg);
			}

			.cta-bg {
				height: 200px;
				width: 2000px;
				position: absolute;
				left: -10%;

				&.bg-gray {
					background-color: #f4f4f4;
				}

				&.bg-wave {
					background: url(../img/backgrounds/papyrus.png);
				}
			}

			a {
				&:first-of-type {
					transform: translatex(-118px);
				}

				&:nth-of-type(2) {
					transform: translatex(60px);
				}
			}
		}
	}

	.page-inner {
		display: flex;
		transform: translatey(-75px);

		.box-hidden {
			width: 34%;

			&.box-hidden-content {
				display: flex;
				flex-direction: column;
				justify-content: space-around;
				padding-right: 80px;

				h4 {
					text-align: left;
					font-size: 24px;
					font-weight: 800;

					&:nth-of-type(even) {
						text-align: right;
					}
				}
			}
		}

		.page-content {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			width: 67%;

			.content-description {
				p {
					margin-top: 20px;
					font-size: 14px;
					color: #000;
					text-align: justify;
				}

				h3 {
					margin: 40px 0 20px 0;
					font-size: 26px;
					font-weight: 700;
					text-align: right;
					line-height: 36px;
				}

				ul {
					margin-top: 10px;
					list-style-type: none;
					padding-left: 25px;
					text-align: justify;

					li {
						font-size: 14px;
						color: #000;

						&:before {
							display: block;
							content: url(../img/icons/arrow.svg);
							height: 15px;
							min-width: 15px;
							max-width: 15px;
							fill: @color1;
							transition: all 350ms;
							transform: translate(-25px, 18px);
						}

						&:hover:before {
							transform: translate(-28px, 18px);
						}

						a {
							color: @color1;
							text-decoration: underline !important;
							font-weight: 600;
							transition: color 350ms;

							&:hover {
								color: #000;
							}
						}
					}
				}
			}

			.faq-item {
				width: 100%;

				&:not(:first-of-type) {
					padding-top: 40px;
				}

				.heading {
					color: #000;
					display: block;
					font-size: 11px;
					font-weight: 600;
					display: inline-block;

					&:after {
						content: '';
						height: 3.5px;
						width: 100px;
						display: block;
						background: #000;
						margin-top: 15px;
					}
				}

				.flexbox-description {
					position: relative;
					display: flex;
					flex-direction: column;
					justify-content: flex-start;

					h3 {
						font-size: 19px;
						color: #000;
						margin-top: 15px;
						line-height: 30px;
					}

					p {
						font-size: 13px;
						color: #000;
						line-height: 24px;
						margin-top: 10px;
					}
				}
			}
		}
	}
}

section.section-kontakt {
	padding: 40px 0 80px;
	overflow: hidden;

	.section-wrapper {
		display: flex;
		justify-content: space-between;

		.box-contact {
			width: 40%;
			display: flex;
			flex-direction: column;
			justify-content: center;

			h3 {
				color: #000;
				font-weight: 800;
				font-size: 28px;
				line-height: 1.2;
			}

			address {
				ul {
					margin-top: 20px;

					li {
						font-weight: 600;
						margin-top: 5px;

						.link {
							font-weight: 600;
							color: @color1;
							transition: color 350ms;

							&:hover {
								color: #000;
							}
						}
					}
				}
			}
		}

		.map-wrapper {
			position: relative;
			width: 58%;
			height: 450px;
			display: flex;
			align-items: center;
			justify-content: flex-end;

			.map-bg {
				background: url(../img/backgrounds/papyrus.png);
				position: absolute;
				width: 2000px;
				left: -10%;
				height: 100%;
				z-index: -1;
			}

			.wave-2 {
				position: absolute;
				height: 15px;
				left: -7%;
				top: 85%;
				transform: translateX(-60%) rotate(-10deg);
				z-index: 111;
			}

			iframe {
				position: absolute;
				width: 100%;
				height: 80%;
				top: 10%;
				left: 5%;
			}
		}
	}
}

section.section-footer {
	background: #f5f5f5;
	position: relative;

	.footer-slogan {
		background: url(../img/backgrounds/papyrus-blue.png);
		padding: 17px 0;
		text-align: center;

		p {
			font-size: 14px;
			font-weight: 700;
			color: #fff;
		}
	}

	.footer-flexbox {
		display: flex;
		justify-content: space-between;
		position: relative;

		.flexbox-service {
			margin-top: 75px;

			&.service-about {
				.service-logo {
					img {
						height: 55px;
					}
				}

				.service-description {
					margin-top: 20px;

					p {
						color: #444;
						font-size: 14px;
					}
				}
			}

			ul {
				li {
					margin-top: 5px;

					&.heading {
						margin-top: 0;
						padding-bottom: 10px;
						font-size: 18px;
						color: #444;
					}

					a {
						font-size: 13px;
						color: #444;
						transition: all 0.3s;

						b {
							font-size: 16px;
						}

						span {
							font-size: 10px;
						}

						&:hover {
							color: @color1;
						}
					}

					p {
						font-size: 12px;
						color: #444;

						b {
							font-weight: 400;
							font-size: 14px;
						}

						span {
							font-size: 10px;
						}
					}
				}
			}

			.list-social {
				display: flex;
				align-items: center;
				justify-content: flex-end;

				li {
					margin-right: 20px;

					a {
						img {
							height: 25px;
						}
					}

					&:last-child {
						margin-right: 0;
					}
				}
			}
		}
	}

	.alert {
		position: relative;
		background: none;
		border: none;
		border-radius: 0;
		margin-top: 55px;
		padding: 0;

		p {
			font-size: 14px;
			color: #444;
			font-weight: 300;
		}

		.alert-links {
			margin-top: 10px;

			a {
				color: #444444;
				cursor: pointer;
				transition: all 0.3s;
				margin-right: 10px;
				font-size: 13px;
				font-weight: 500;

				&:hover {
					color: @color1;
				}
			}
		}
	}

	.footer-credits-wrapper {
		margin-top: 40px;
		padding: 25px 0;
		background: #e5e5e5;

		.footer-credits {
			display: flex;
			justify-content: space-between;
			align-items: center;
			position: relative;

			p {
				font-size: 13px;
				color: #444;
				font-weight: 300;

				a {
					color: @color1;
					font-weight: 600;
				}
			}

			.footer-payment {
				display: flex;
				align-items: center;

				p {
					margin-right: 15px;
				}

				img {
					margin-right: 10px;
					height: 19px;

					&:last-child {
						margin-right: 0;
					}
				}
			}
		}
	}
}